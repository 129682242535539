* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'Poppins', sans-serif;

}

.fixed {
	background-color: white;
	border-color: red;
	border-width: 1px solid red;
}

.main-nav{
	padding: 10px;
}
.nav-item {
	padding: 10px;
	font-size: 15px;
}
.nav-item:after {
	content: '';
	width: 0%;
	height: 2px;
	background: #8c40d4 !important;
	display: block;
	margin: auto;
	transition: .5s;
}

.nav-item:hover:after {
	width: 100%;
	background-color: #8c40d4 !important;
	list-style-type: none;
}
.navbar-toggler-icon{
	background-color: white;
}
.logo img {
	width: 100px;
}
.btn-nav {
	background-color: #8c40d4;
	color: white;
	padding: 10px;
	border-radius: 10px;
}
.btn-nav:hover{
	color: white;
	background-color: #7818d2 ;
}