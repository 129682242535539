.rowteam {
    background-color: #e5e5e5;
    margin-top: 15%;
}

.team {
    text-align: center;
    margin-top: 5%;
}

.team h3 {
    font-size: 30px;
}

.team p {
    font-size: 20px;
}
.cart-wrapeer {
    position: relative;
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;

    &:active,
    &:hover,
    &:focus {
        outline: none;
        border: none;
    }
}

.cart {
    width: 100%;
    height: 300px;
    border-radius: 16px;
    overflow: hidden;
    cursor: pointer;
    position: relative;

    .cart-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background-color: #000;
        transition: .5s;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &:hover {
        img {
            opacity: .4;
            transition: .5s;
        }

        .cart-image {
            transform: translateY(-100px);
            transition: all .9s;
        }
    }
}
@media screen and (max-width:768px){
    .slick-non{
        width: 600px !important;
    } 
}
@media screen and (max-width:490px){
    .slick-non{
        width: 250px !important;
    } 
//     .slick-next{
//   display: none;
//     }
}
// .slick-non{
//     width: 1150px !important;
// }
.social-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    display: flex;
    li {
        list-style: none;
        a{
            position: relative;
            display: block;
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background: #fff;
            font-size: 23px;
            color: #333;
            font-weight: bold;
            margin: 0 6px;
            transition: .4s;
            transform: translateY(200px);
            opacity: 0;
        }
    }

}
.cart:hover{
    .social-icon li a{
        transform: translateY(0px);
        opacity: 1;
        justify-content: center;
    }
}
.social-icon li a:hover{
background: #000;
transition: .2s;
.fa{
    color: #fff;
}
}
.social-icon li a .fa{
    transition: .8s;
    &:hover{
        transform: rotateY(360deg);
        color: #fff;
    }
}
.cart:hover li:nth-child(1) a{
    transition-delay: .1s;
}
.cart:hover li:nth-child(2) a{
    transition-delay: .2s;
}
.cart:hover li:nth-child(3) a{
    transition-delay: .3s;
}
// .cart-wrapeer{
//     padding: 20px;
// }
.details{
    position: absolute;
    bottom: 400px;
    left: 0;
    background: #fff;
    width: 100%;
    height: 120px;
    z-index: 1;
    padding: 10px;
    transition: .6s;
    h2{
        font-size: 20px;
        margin: 30px 0;
        padding: 0;
        text-align: center;
        .job-title{
            font-size: 16px;
            line-height: 2;
            color: #333;
            font-size: 300;
            display:  block;
        }
    }
}
.cart:hover{
    .details{
        bottom: 0;
    }
}
 
.carousel{
    .slick-dots{
        bottom: -2.5vw;
        li{
            button{
                width: 20px;
                height: 20px;
                border: 1px solid #fff;
                border-radius: 20px;
                &:before{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 10px;
                    height: 10px;
                    border-radius: 10px;
                    content: "";
                    text-align: center;
                    opacity: .5;
                    color: #000;
                    background-color: #fff;
                    transform: translate(-50%, -50%);
                    transition: all .3s ease-in-out;
                }
            }
            &.slick-active,
            &:hover{
            button{
                border-color: tomato;
                &:before{
                    background-color: tomato;
                    opacity: 1;
                }
            }
            }
           
        }
    }
}


// .cart-wrapper{
//     position: relative;
//     width: 100%;
//     padding: 0 10px;
//     box-sizing: border-box;
// }
// .cart-wrapper :active :hover :focus{
//     outline: none;
//     border: none;
// }
// .cart{
//     width: 100%;
//     height: 300px;
//     border-radius: 16px;
//     overflow: hidden;
//     cursor: pointer;
//     position: relative;
// }
// .cart-image{
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     z-index: 2;
//     background-color: #000;
//     transition: .5s;
// }
// .cart-image img{
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
// }
//  .cart-image img:hover {
//     opacity: .4;
//     transition: .5s;

// } 